
/**
 * Allows splitscreen view. Left side is SSR, right one will only be visible on splt.
 */

export default {
    name: "Splitscreen",
    emits: ["click-outside"],
    props: {
        split: {
            type: Boolean,
            default: false,
        },
        /** Width of left element with css unit */
        leftWidth: {
            type: String,
            default: "60%",
        },
        /** Width of right element with css unit, overrides left width */
        rightWidth: {
            type: String,
            default: null,
        },
        noModal: {
            type: Boolean,
            default: false,
        },
        /** Loads data inside slots of hidden elements */
        preload: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            clickOutsideListener: null,
        }
    },
    computed: {
        showSplitscreen() {
            return this.split && this.$breakpoint.lgAndUp
        },
        showDialog() {
            return this.split && !this.$breakpoint.lgAndUp && !this.noModal
        },
        computedRightWidth() {
            if (this.rightWidth) {
                return this.rightWidth
            }
            return `calc(100% - ${this.leftWidth})`
        },
        computedLeftWidth() {
            if (this.rightWidth) {
                return `calc(100% - ${this.rightWidth})`
            }
            return this.leftWidth
        },
        leftStyle() {
            if (!this.showSplitscreen) return "width: 100%"
            return { width: this.computedLeftWidth }
        },
        rightStyle() {
            const style = {
                width: this.rightWidth ?? this.computedRightWidth,
                transform: this.showSplitscreen
                    ? "translateX(0)"
                    : `translateX(100%)`,
            }
            return style
        },
    },
    watch: {
        showSplitscreen(value) {
            if (value)
                setTimeout(() => {
                    this.clickOutsideListener = {
                        click: () => this.$emit("click-outside"),
                    }
                }, 1000)
            else this.clickOutsideListener = null
        },
    },
}
