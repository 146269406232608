
export default {
    name: "OImage",
    props: {
        loading: {
            default: "lazy",
            type: String,
        },
        alt: {
            default: null,
            type: String,
        },
        width: {
            default: null,
            type: Number,
        },
        height: {
            default: null,
            type: Number,
        },
        image: {
            required: true,
            type: [Object, String],
        },
        fit: {
            default: "cover",
            type: String,
        },
        draggable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        styleAttribute() {
            return "object-fit: " + this.fit + ";"
        },
        imageSource() {
            if (this.image && this.image.src) {
                return this.image.src
            } else {
                return this.image
            }
        },
    },
}
