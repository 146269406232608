
import { mapGetters } from "vuex"
import { speechbubble_message, speechbubble_filled } from "@/assets/icons/js"
import { mdiBookmarkCheck, mdiBookmarkCheckOutline } from "@mdi/js"
import { routeToBusinessAdminJobs } from "@/helpers/routing"
import traineeNavigationMixin from "@/mixins/traineeNavigationMixin"

export default {
    name: "BottomNav",
    mixins: [traineeNavigationMixin],
    props: {
        floating: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters("routing", ["previousSearchRoute"]),
        ...mapGetters("navigation", ["menuItems"]),
        unreadMessages() {
            return this.$store.getters["chat/getTotalUnreadMessages"]
        },
        showProfileHint() {
            return (
                this.$auth.loggedIn &&
                !this.$auth.user.trainee?.is_profile_complete
            )
        },
        items() {
            const items = []
            if (this.$can.school() || this.$can.region()) {
                return this.menuItems.map((item) => {
                    item.isNew = true
                    return item
                })
            }
            if (this.$can.business()) {
                items.push({
                    position: 1,
                    name: "Startseite",
                    class: "company-home",
                    exact: true,
                    icon: require("@/assets/icons/v2/home.5.svg"),
                    link: { name: "betrieb" },
                })
                items.push({
                    position: 1,
                    name: "Schulnetzwerk",
                    class: "company-schools",
                    exact: true,
                    icon: require("@/assets/icons/v2.1/icon_education_bottomnav.svg"),
                    link: { name: "betriebe-schulnetzwerk" },
                })
                items.push({
                    position: 3,
                    name: "Stellen",
                    class: "company-jobs",
                    exact: true,
                    icon: require("@/assets/icons/v2.1/icon_job_black.svg"),
                    link: routeToBusinessAdminJobs(this.$auth.user),
                })
            }
            if (this.$can.trainee() || !this.$auth.loggedIn) {
                items.push({
                    position: 1,
                    name: "Suchen",
                    class: "home",
                    exact: true,
                    icon: require("@/assets/icons/v2/search.2.svg"),
                    link: this.traineeHomeRoute,
                })
                items.push({
                    position: 2,
                    name: "Favoriten",
                    class: "favorites",
                    link: { name: "favoriten" },
                    icon: mdiBookmarkCheckOutline,
                    iconActive: mdiBookmarkCheck,
                })
                items.push({
                    position: 3,
                    name: "Dein Profil",
                    class: "settings",
                    isBadgeActive: this.showProfileHint,
                    badgeContent: "!",
                    badgeColor: "#ffb41d",
                    icon: require("@/assets/icons/v2/profile.3.svg"),
                    link: { name: "i-profile" },
                })
            }
            items.push({
                position: 2,
                name: "Chat",
                class: "chat",
                isBadgeActive: this.unreadMessages && this.unreadMessages > 0,
                badgeContent: this.unreadMessages,
                badgeColor: "notification effect--pulse",
                icon: speechbubble_message,
                iconActive: speechbubble_filled,
                link: { name: "chat" },
            })
            /** Against Deadlock state (loggedIn, but no proper user object defined)
             * This can happen, cause unknown. If this happens in the app,
             * users cannot logout anymore. Therefore deliver a fallback to exit this broken state
             */
            if (items.length == 1) {
                items.push({
                    position: 2,
                    name: "Logout",
                    class: "logout",
                    icon: require("@/assets/icons/v2.1/icon_exclamationmark.svg"),
                    action: () => this.$store.dispatch("account/logout"),
                })
            }
            return items.sort((a, b) => a.position - b.position)
        },
    },
    methods: {
        initLink(link) {
            if (link instanceof Function) {
                // the link function is executed with 'this' context
                return link.call(this)
            } else return link
        },
        isImage(source) {
            if (!source) return false
            return source.startsWith("data") || source.includes("/_nuxt/")
        },
        isSearchSection(name) {
            return (
                name === "Suchen" &&
                [
                    "unternehmen-locationSlug",
                    "praktikum-locationSlug",
                    "ausbildung-locationSlug",
                ].includes(this.$route.name)
            )
        },
        isActive(route) {
            return this.$route.name.includes(route.name)
        },
    },
}
