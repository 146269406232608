
import BottomNav from "@/components/layout/navigation/BottomNav"
import Footer from "@/components/layout/navigation/Footer"
import Splitscreen from "@/components/layout/Splitscreen"
import defaultMeta from "@/mixins/defaultMeta"
import { mapGetters } from "vuex"
import { Wormhole } from "portal-vue"
import traineeNavigationMixin from "@/mixins/traineeNavigationMixin"

/**
 * Mobile: smAndDown
 */
export default {
    name: "ModernLayout",
    components: {
        Onboarding: () => import("@/components/onboarding/Onboarding"),
        CookieInformation: () =>
            import("@/components/layout/CookieInformation"),
        SVGGradient: () => import("@/components/base/design/SVGGradient"),
        ImpersonateHeader: () => import("@/components/admin/ImpersonateHeader"),
        ProgressCircular: () => import("@/components/base/ProgressCircular"),
        Login: () => import("@/components/auth/Login"),
        Sidebar: () => import("@/components/layout/navigation/Sidebar"),
        LivestreamBadge: () => import("@/components/layout/LivestreamBadge"),

        Footer,
        BottomNav,
        Splitscreen,
    },
    mixins: [defaultMeta, traineeNavigationMixin],
    computed: {
        ...mapGetters("cookie", ["showCookieConsent"]),
        ...mapGetters("account", ["onboarding"]),
        ...mapGetters("chat", { isChatSelected: "isSelected" }),
        ...mapGetters("ui", ["loadingSpinner"]),
        ...mapGetters("navigation", ["actions", "menuItems"]),
        ...mapGetters("meta", ["meta"]),
        showFooter() {
            return (
                !(this.$auth.loggedIn && this.$breakpoint.mdAndUp) ||
                this.meta.footer?.persistent
            )
        },
        noSplitscreenModal() {
            return !this.meta.splitscreen?.modal
        },
        mainStyle() {
            /* Make main at least 100vh being white */
            const { footer, insetFooter, bottom } = this.$vuetify.application
            return {
                "min-height": `calc(100vh + ${
                    footer + insetFooter + bottom
                }px)`,
            }
        },
        isSplit() {
            return Wormhole.hasContentFor("splitscreen")
        },
        showSidebar() {
            if (this.$breakpoint.smAndDown) return false
            if (this.$route.name === "betriebe-slug-jobs-jobSlug") return false
            return (
                this.$can.school() || this.$can.region() || this.$can.business()
            )
        },
        keepAlivePages() {
            const pages = [
                "AusbildungLocationSlug",
                "PraktikumLocationSlug",
                "Chat",
            ]
            if (this.$can.business()) {
                pages.push(new RegExp("Betrieb.*"))
            }
            return pages
        },
        noKeepAlivePages() {
            const pages = []
            if (this.$can.business()) {
                pages.push("betriebe-slug-jobs-bearbeiten")
            }
            return pages
        },
        showLivestreamBadge() {
            const validRoutes = [
                "unternehmen-locationSlug",
                "ausbildung-locationSlug",
                "praktikum-locationSlug",
                "index",
                "betrieb",
                "schule",
                "region",
            ]
            return (
                this.$livestream.isReady &&
                this.$livestream.isUpcoming &&
                validRoutes.includes(this.$route.name)
            )
        },
    },
    methods: {
        onOnboardingFinished() {
            this.$router.push(this.traineeHomeRoute)
        },
    },
}
