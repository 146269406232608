export default {
    head() {
        return {
            meta: [
                {
                    hid: "og:url",
                    name: "og:url",
                    /* Sentry error 2739106250 | coalesce protection against random undefined*/
                    content: this.$config.baseURL + this.$route?.path,
                },
            ],
        }
    },
}
