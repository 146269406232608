
import Button from "@/components/base/Button"
import OImage from "@/components/base/image/OImage"

import {
    routeToArbeitgeberRegistration,
    routeToSettings,
} from "@/helpers/routing"
import { mapGetters } from "vuex"
import { mdiChevronRight, mdiLock, mdiCog } from "@mdi/js"

/**
 * Available design meta flags:
 *  footer: {
 *      dark, small, transparent, hidden, bottomPadding,
 *      showMobileAuth, directRegistration, showAgb, showSchoolAgb, showAbout,
 *      showPowered, showLegal, showSocial, showForBusinesses
 *  }
 * Indirect meta flags:
 *      hideBottomNav
 */
export default {
    name: "Footer",
    components: { OImage, Button },
    props: {
        /** Design: Inverts black / white coloring */
        dark: {
            type: Boolean,
            default: null,
        },
        /** Design: Small variation just logo, imprint, dataprotection */
        small: {
            type: Boolean,
            default: null,
        },
        /** Design: Transparent background variation */
        transparent: {
            type: Boolean,
            default: null,
        },
        /** Makes Oabat link open in new tab */
        newTab: {
            type: Boolean,
            default: false,
        },
        /** Adds login/logout Buttons to mobile */
        showMobileAuth: {
            type: Boolean,
            default: null,
        },
        /** Adds the "powered by" title */
        showPowered: {
            type: Boolean,
            default: null,
        },
        /** Adds about links */
        showAbout: {
            type: Boolean,
            default: true,
        },
        /** Adds legal links */
        showLegal: {
            type: Boolean,
            default: true,
        },
        /** Adds social links */
        showSocial: {
            type: Boolean,
            default: true,
        },
        /** Adds contact button */
        showContact: {
            type: Boolean,
            default: true,
        },
        /** Specialcase: Replaces Nutzungsbedingungen with AGB */
        showAgb: {
            type: Boolean,
            default: false,
        },
        /** Shows AGB für Bildungseinrichtungen */
        showSchoolAGB: {
            type: Boolean,
            default: false,
        },
    },
    fetch() {
        this.meta = this.metaData
    },
    data: () => ({
        mdiLock,
        mdiChevronRight,
        mdiCog,
        meta: {},
        social: [
            {
                icon: require("@/assets/icons/v2.1/icon_facebook.svg"),
                alt: "facebook",
                link: "https://www.facebook.com/oabat",
            },
            {
                icon: require("@/assets/icons/v2.1/icon_linkedin.svg"),
                alt: "linkedIn",
                link: "https://www.linkedin.com/company/oabat/",
            },
            {
                icon: require("@/assets/icons/v2.1/icon_instagram.svg"),
                alt: "instagram",
                link: "https://www.instagram.com/oabat/",
            },
        ],
        about: [
            {
                link: "https://about.oabat.de/",
                text: "Über uns",
            },
            {
                link: "https://about.oabat.de/presse/",
                text: "Presse",
            },
            {
                link: "https://about.oabat.de/newsletter/",
                text: "Newsletter",
            },
        ],
    }),
    watch: {
        /* Avoid flickering by setting meta when new page is rendered */
        $route() {
            // Avoid redundant updates
            if (JSON.stringify(this.meta) !== JSON.stringify(this.metaData)) {
                this.meta = this.metaData
            }
        },
    },
    /* Recalculate footer size for v-main if changed */
    updated() {
        if (!this.$refs.footer) return
        if (
            this.$vuetify.application.insetFooter !==
            this.$refs.footer.$el.clientHeight
        ) {
            this.$refs.footer.callUpdate()
        }
    },
    computed: {
        ...mapGetters("meta", { metaData: "meta" }),
        ...mapGetters("search", { searchChannel: "channel" }),
        routeToSettings() {
            return routeToSettings(this.$auth.user)
        },
        additionalLinks() {
            let elements = [
                {
                    text: "Datenschutz",
                    link: "/datenschutz",
                },
            ]
            if (!this.isSmall || this.elements.agb) {
                if (this.elements.agb) {
                    elements.push({
                        text: "AGB",
                        link: "/agb",
                    })
                } else {
                    elements.push({
                        text: "Nutzungsbedingungen",
                        link: this.elements.schoolAgb
                            ? "/nutzungsbedingungen-fuer-bildungseinrichtungen"
                            : "/nutzungsbedingungen",
                    })
                }
            }
            elements.push({
                text: "Impressum",
                link: "/impressum",
            })
            return elements
        },
        isDark() {
            if (this.dark !== null) return this.dark
            return this.meta.footer?.dark ?? false
        },
        isSmall() {
            if (this.small !== null) return this.small
            return this.meta.footer?.small ?? false
        },
        isTransparent() {
            if (this.transparent !== null) return this.transparent
            return this.meta.footer?.transparent ?? false
        },
        isHidden() {
            return this.meta.footer?.hidden ?? false
        },
        /** Footer elements to be displayed */
        elements() {
            const meta = this.meta.footer ?? {}
            const auth =
                this.$breakpoint.smAndDown &&
                (this.showMobileAuth ?? !!meta.showMobileAuth)
            const settings =
                auth && !meta.hideSettings && !meta.showAdvancedSettings
            const advancedSettings =
                this.$auth.loggedIn && !!meta.showAdvancedSettings
            return {
                topLogo: !auth && !this.isSmall,
                social: (meta.showSocial ?? !!this.showSocial) && !this.isSmall,
                legal: meta.showLegal ?? !!this.showLegal,
                about: (meta.showAbout ?? !!this.showAbout) && !this.isSmall,
                contact:
                    (meta.showContact ?? !!this.showContact) && !this.isSmall,
                powered: meta.showPowered ?? !!this.showPowered,
                forBusinesses:
                    this.$breakpoint.smAndDown && meta.showForBusinesses,
                auth,
                settings,
                advancedSettings,
                agb: this.showAgb || meta.showAgb || this.$can.business(),
                schoolAgb: !!(
                    this.$can.school() ||
                    this.showSchoolAgb ||
                    meta.showSchoolAgb
                ),
            }
        },
        elementHash() {
            return JSON.stringify(this.elements)
        },
        footerClasses() {
            return {
                "footer--inset": !this.meta.hideBottomNav,
                "footer--transparent": this.isTransparent,
                "footer--small": this.isSmall,
                "footer--dark": this.isDark,
                "black--text": this.isDark,
                "white--text": !this.isDark,
                white: this.isDark && !this.isTransparent,
                black: !this.isDark && !this.isTransparent,
            }
        },
        footerStyle() {
            const pb = this.meta.footer?.bottomPadding
            return {
                "padding-bottom": pb ? `${pb}px` : undefined,
                /* Overwrites vuetifys footer inset prop from "left" to use "padding-left" */
                left: "0px",
                "padding-left": `${this.$vuetify.application.left || 16}px`,
            }
        },
        forBusinessURL() {
            if (this.meta.footer?.directRegistration) {
                return routeToArbeitgeberRegistration({
                    channel: this.searchChannel,
                })
            }
            return null
        },
        target() {
            return this.meta.footer?.target ?? "_blank"
        },
    },
    methods: {
        initLink(link) {
            if (link instanceof Function) {
                // the link function is executed with 'this' context
                return link.call(this)
            } else return link
        },
        onLogin() {
            this.$store.dispatch("account/prepareOnboarding", true)
            this.$store.dispatch("account/showAuth")
        },
        onLogout() {
            this.$store.dispatch("account/logout")
        },
    },
}
